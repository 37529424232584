
<div *ngIf="auth" class="container">
    <div class="row row-bottom-padded-lg" id="produkte" >

        <div style="margin-top:200px;">
            Eingabe für Adhoc Meldung Website:
        </div>
        <textarea style="width:700px;height:200px;"  [(ngModel)]="text">
        </textarea><br>
        <input type="button" (click)="reset()" value="löschen"> 
        <input type="button" (click)="save()" value="speichern">        
        <input type="button" (click)="logout()" value="logout">
    </div>
</div>
<div *ngIf="!auth" class="container">
    <div class="row row-bottom-padded-lg" id="produkte" >

        <div style="margin-top:200px;">
            Bitte Passwort eingeben
        </div>
        <input type="password"  [(ngModel)]="passwort">
        <input type="button" (click)="authenticate()" value="login">
    </div>
</div>