import { Component, OnInit } from '@angular/core';
import { NewsService } from '../news.service';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.css']
})


export class NewsComponent implements OnInit {

  
text:string;
passwort:string;
auth:boolean = false;

  constructor(private news:NewsService) { 

    if(localStorage.getItem('auth') == 'true'){
      this.auth = true;
    }

    let val = news.getNews().subscribe(val=>{
      this.text = val.text;
    console.log(val);
    if(val){
      console.log(val.text);
    }
    

    });
    console.log("info");
  }

  ngOnInit(): void {
  }


  reset(){
    this.text="";
    this.news.updateNews("").subscribe(val=>{
      alert("Erfolgreich gelöscht.");
      location.reload();
    }); 
  }

  save(){
    this.news.updateNews(this.text).subscribe(val=>{
      alert("Erfolgreich hinzugefügt.");
      location.reload();
    });
  }

  authenticate(){
    if(this.passwort == "hanf2022!"){

      localStorage.setItem('auth','true');
      location.reload();
    }else{
      alert("Falsches Passwort");
    }

  }

  logout(){
    localStorage.removeItem('auth');
    location.reload();

  }

}
