<section style="background-color:#f4f4f4;">
<div class="container produkte">
    <div class="row" style="margin-top:100px;">
    <div class="col-lg-12">
        <p style="text-align:center;font-size:30px;color:#2b542c; ">Unsere Produkte - Jetzt anfragen!
        </p>
        <span style="font-size:18px;">
            Wählen Sie Ihre Lieblingsprodukte aus, und fragen diese bei uns an. Jede Anfrage wird persönlich von uns bearbeitet 
            und gepackt. Wir freuen uns auf Ihre Bestellanfragen!
            <hr>
        </span>
    </div>
      <div class="col-lg-9">
        <div class="row" >
            <div class="col-lg-4 col-md-6 mb-4">
                <div class="card h-100">
                <a href="#">
                    <img class="card-img-top img-responsive " src="images/produkte/nuesse_250.jpg" alt=""></a>
                <div class="card-body">
                    <h4 class="card-title">
                    <a href="#">Hanf Ge-Nüsschen </a>
                    </h4>
                    <h5>250g 4,00€</h5>
                    <p class="card-text">
                        mit wertvollem pflanzlichen Eiweiß und allen essentiellen Nährstoffen.
                    </p>   
                    <h5>Bestens geeignet:</h5>
                        <ul>
                            <li>als Knabbernüsschen</li>
                            <li>im Müsli</li>
                            <li>zum Backen</li>
                            <li>zum Verfeinern</li>
                        </ul>
        
                </div>
                <div class="card-footer" >
                    <div class="col-lg-3" style="cursor:pointer;">
                    <svg data-toggle="modal" data-target="#exampleModal" (click)=selectProduct(hanfnuesse250) width="2em" height="2em" viewBox="0 0 16 16" class="bi bi-bag-check" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" d="M8 1a2.5 2.5 0 0 0-2.5 2.5V4h5v-.5A2.5 2.5 0 0 0 8 1zm3.5 3v-.5a3.5 3.5 0 1 0-7 0V4H1v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V4h-3.5zM2 5v9a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V5H2z"/>
                        <path fill-rule="evenodd" d="M10.854 8.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 0 1 .708-.708L7.5 10.793l2.646-2.647a.5.5 0 0 1 .708 0z"/>
                    </svg>
                    </div>
                    <div class="col-lg-3">
                        <a data-toggle="collapse" href="#nuesseDetails">
                            <svg width="1.5em" height="1.5em" style="margin-top:5px;" viewBox="0 0 16 16" class="bi bi-info-circle-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412l-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM8 5.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
                              </svg>
                        </a>   
                    </div>
                </div>
                           
                <div class="collapse" id="nuesseDetails">
                    <div class="row">
                        <div class="col-lg-12">
                            <i>Durchschnittlicher Nährwert je 100g</i>
                        </div>
                        <div class="col-lg-10">
                            Energie
                        </div>
                        <div class="col-lg-2">
                            1896kJ/459Kcal
                        </div>
                        <div class="col-lg-10">
                            Fett
                        </div>
                        <div class="col-lg-2">
                            31,80g
                        </div>
                        <div class="col-lg-10">
                            davon gesättigte Fettsäuren
                        </div>
                        <div class="col-lg-2">
                            3,20g
                        </div>
                        <div class="col-lg-10">
                            einfach ungesättigte Fettsäuren
                        </div>
                        <div class="col-lg-2">
                            3,28g
                        </div>
                        <div class="col-lg-10">
                            mehrfach ungesättigte Fettsäuren
                        </div>
                        <div class="col-lg-2">
                            22,10g	
                        </div>
                        <div class="col-lg-10">
                            Kohlenhydrate
                        </div>
                        <div class="col-lg-2">
                            7,40g
                        </div>
                        <div class="col-lg-10">
                            davon Zucker
                        </div>
                        <div class="col-lg-2">
                            6,65g
                        </div>
                        <div class="col-lg-10">
                            Ballaststoffe (gesamt)
                        </div>
                        <div class="col-lg-2">
                            30,60g
                        </div>
                        <div class="col-lg-10">
                            Eiweiß
                        </div>
                        <div class="col-lg-2">
                            20,20g
                        </div>
                        <div class="col-lg-10">
                            Salz
                        </div>
                        <div class="col-lg-2">
                            0,01g
                        </div>                        
                        <div class="col-lg-12">
                            <p style="margin-top:20px;">
                                Alle Angaben unterliegen den üblichen Schwankungen von Naturprodukten.
                            </p>
                        </div>
                    </div>		
                </div>

                
                </div>
            </div>
            <div class="col-lg-4 col-md-6 mb-4">
                <div class="card h-100">
                <a href="#"><img class="card-img-top  img-responsive" src="images/produkte/nuesse_500.jpg" alt=""></a>
                <div class="card-body">
                    <h4 class="card-title">
                    <a href="#">Hanf Ge-Nüsschen  </a>
                    </h4>
                    <h5>500g 7,00€</h5>
                    <p class="card-text">
                        mit wertvollem pflanzlichen Eiweiß und allen essentiellen Nährstoffen.
                    </p>   
                    <h5>Bestens geeignet:</h5>
                        <ul>
                            <li>als Knabbernüsschen</li>
                            <li>im Müsli</li>
                            <li>zum Backen</li>
                            <li>zum Verfeinern</li>
                        </ul>
                </div>
                <div class="card-footer">
                    <div class="card-footer" >
                        <div class="col-lg-3" style="cursor:pointer;">
                        <svg data-toggle="modal" data-target="#exampleModal" (click)=selectProduct(hanfnuesse500) width="2em" height="2em" viewBox="0 0 16 16" class="bi bi-bag-check" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" d="M8 1a2.5 2.5 0 0 0-2.5 2.5V4h5v-.5A2.5 2.5 0 0 0 8 1zm3.5 3v-.5a3.5 3.5 0 1 0-7 0V4H1v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V4h-3.5zM2 5v9a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V5H2z"/>
                            <path fill-rule="evenodd" d="M10.854 8.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 0 1 .708-.708L7.5 10.793l2.646-2.647a.5.5 0 0 1 .708 0z"/>
                        </svg>
                        </div>
                        <div class="col-lg-3">
                            <a data-toggle="collapse" href="#nuesseDetails2">
                                <svg width="1.5em" height="1.5em" style="margin-top:5px;" viewBox="0 0 16 16" class="bi bi-info-circle-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412l-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM8 5.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
                                  </svg>
                            </a>   
                        </div>
                    </div>
                </div>           
                <div class="collapse" id="nuesseDetails2">
                    <div class="row">
                        <div class="col-lg-12">
                            <i>Durchschnittlicher Nährwert je 100g</i>
                        </div>
                        <div class="col-lg-10">
                            Energie
                        </div>
                        <div class="col-lg-2">
                            1896kJ/459Kcal
                        </div>
                        <div class="col-lg-10">
                            Fett
                        </div>
                        <div class="col-lg-2">
                            31,80g
                        </div>
                        <div class="col-lg-10">
                            davon gesättigte Fettsäuren
                        </div>
                        <div class="col-lg-2">
                            3,20g
                        </div>
                        <div class="col-lg-10">
                            einfach ungesättigte Fettsäuren
                        </div>
                        <div class="col-lg-2">
                            3,28g
                        </div>
                        <div class="col-lg-10">
                            mehrfach ungesättigte Fettsäuren
                        </div>
                        <div class="col-lg-2">
                            22,10g	
                        </div>
                        <div class="col-lg-10">
                            Kohlenhydrate
                        </div>
                        <div class="col-lg-2">
                            7,40g
                        </div>
                        <div class="col-lg-10">
                            davon Zucker
                        </div>
                        <div class="col-lg-2">
                            6,65g
                        </div>
                        <div class="col-lg-10">
                            Ballaststoffe (gesamt)
                        </div>
                        <div class="col-lg-2">
                            30,60g
                        </div>
                        <div class="col-lg-10">
                            Eiweiß
                        </div>
                        <div class="col-lg-2">
                            20,20g
                        </div>
                        <div class="col-lg-10">
                            Salz
                        </div>
                        <div class="col-lg-2">
                            0,01g
                        </div>
                        <div class="col-lg-12">
                            <p style="margin-top:20px;">
                            Alle Angaben unterliegen den üblichen Schwankungen von Naturprodukten.
                            </p>
                        </div>
                    </div>		
                </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 mb-4">
                <div class="card h-100">
                <a href="#"><img class="card-img-top  img-responsive" src="images/produkte/hanfoel.jpg" alt=""></a>
                <div class="card-body">
                    <h4 class="card-title">
                    <a href="#">Altmannsteiner Hanföl </a>
                    </h4>
                    <h5>250ml 8,50€</h5>
                    <p class="card-text">
                        100% Hanf-Öl kalt gepresst
                    </p>
                </div>
                <div class="card-footer" style="display:none;">
                    
                    <button (click)=selectProduct(hanfoel)>hinzufügen</button>
                    <small class="text-muted">&#9733; &#9733; &#9733; &#9733; &#9734;</small>
                </div>
                </div>
            </div>
        </div>
        <div class="row" style="margin-top:50px">
            <div class="col-lg-4 col-md-6 mb-4">
                <div class="card h-100">
                <a href="#"><img class="card-img-top  img-responsive" src="images/produkte/nuesse_kandiert.jpg" alt=""></a>
                <div class="card-body">
                    <h4 class="card-title">
                    <a href="#">Hanfsamen kandiert</a>
                    </h4>
                    <h5>100g 4,90€</h5>
                    <p class="card-text">
                        Knusprig, geröstete Hanfsamen, kandiert mit Kokosblütenzucker und feinem Ceylon Zimt.
                    </p>
                        <h5>Bestens geeignet:</h5>
                        <ul>
                            <li>im Müsli</li>
                            <li>fürs Eis</li>
                            <li>zum Vernaschen</li>
                        </ul>
                </div>
                <div class="card-footer" >
                    <div class="card-footer" >
                        <div class="col-lg-3" style="cursor:pointer;">
                        <svg data-toggle="modal" data-target="#exampleModal" (click)=selectProduct(hanf_kandiert) width="2em" height="2em" viewBox="0 0 16 16" class="bi bi-bag-check" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" d="M8 1a2.5 2.5 0 0 0-2.5 2.5V4h5v-.5A2.5 2.5 0 0 0 8 1zm3.5 3v-.5a3.5 3.5 0 1 0-7 0V4H1v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V4h-3.5zM2 5v9a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V5H2z"/>
                            <path fill-rule="evenodd" d="M10.854 8.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 0 1 .708-.708L7.5 10.793l2.646-2.647a.5.5 0 0 1 .708 0z"/>
                        </svg>
                        </div>
                        <div class="col-lg-3">
                            <a data-toggle="collapse" href="#nuesseDetails3">
                                <svg width="1.5em" height="1.5em" style="margin-top:5px;" viewBox="0 0 16 16" class="bi bi-info-circle-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412l-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM8 5.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
                                  </svg>
                            </a>   
                        </div>
                    </div>
                </div>          
                <div class="collapse" id="nuesseDetails3">
                    <div class="row">
                        <div class="col-lg-12">
                            <i>Durchschnittlicher Nährwert je 100g</i>
                        </div>
                        <div class="col-lg-10">
                            Energie
                        </div>
                        <div class="col-lg-2">
                            2123kJ/509Kcal
                        </div>
                        <div class="col-lg-10">
                            Fett
                        </div>
                        <div class="col-lg-2">
                            31,60g
                        </div>
                        <div class="col-lg-10">
                            davon gesättigte Fettsäuren
                        </div>
                        <div class="col-lg-2">
                            3,0g
                        </div>
                        <div class="col-lg-10">
                            einfach ungesättigte Fettsäuren
                        </div>
                        <div class="col-lg-2">
                            4,5g
                        </div>
                        <div class="col-lg-10">
                            mehrfach ungesättigte Fettsäuren
                        </div>
                        <div class="col-lg-2">
                            24,1g	
                        </div>
                        <div class="col-lg-10">
                            Omega-3-Fettsäuren
                        </div>
                        <div class="col-lg-2">
                            4,3g
                        </div>
                        <div class="col-lg-10">
                            Kohlenhydrate
                        </div>
                        <div class="col-lg-2">
                           36,2g
                        </div>
                        <div class="col-lg-10">
                            davon Zucker
                        </div>
                        <div class="col-lg-2">
                            36,2g
                        </div>
                        <div class="col-lg-10">
                            Eiweiß
                        </div>
                        <div class="col-lg-2">
                            19,9g
                        </div>
                        <div class="col-lg-10">
                            Salz
                        </div>
                        <div class="col-lg-2">
                            0,13g
                        </div>
                        <div class="col-lg-10">
                            Magnesium
                        </div>
                        <div class="col-lg-2">
                            486,8mg
                        </div>
                        <div class="col-lg-12 ">
                            <p style="margin-top:20px;">
                            Alle Angaben unterliegen den üblichen Schwankungen von Naturprodukten.
                            </p>
                        </div>
                    </div>		
                </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 mb-4">
                <div class="card h-100">
                <a href="#"><img class="card-img-top  img-responsive" src="images/produkte/nuesse_geschaelt.jpg" alt=""></a>
                <div class="card-body">
                    <h4 class="card-title">
                    <a href="#">Hanfsamen geschält</a>
                    </h4>
                    <h5>250g 7,80€</h5>
                    <p class="card-text">Geschälte Hanfsamen in Premium-Qualität, angebaut, geerntet und verpackt in Bayern.
                    </p>
                    <h5>Bestens geeignet:</h5>
                    <ul>
                        <li>für Smoothies</li>
                        <li>im Müsli</li>
                        <li>ins Gebäck</li>
                        <li>zum Knabbern</li>
                    </ul>
                </div>
                <div class="card-footer" >
                    <div class="col-lg-3" style="cursor:pointer;">
                    <svg  data-toggle="modal" data-target="#exampleModal" (click)=selectProduct(hanf_geschaelt) width="2em" height="2em" viewBox="0 0 16 16" class="bi bi-bag-check" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" d="M8 1a2.5 2.5 0 0 0-2.5 2.5V4h5v-.5A2.5 2.5 0 0 0 8 1zm3.5 3v-.5a3.5 3.5 0 1 0-7 0V4H1v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V4h-3.5zM2 5v9a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V5H2z"/>
                        <path fill-rule="evenodd" d="M10.854 8.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 0 1 .708-.708L7.5 10.793l2.646-2.647a.5.5 0 0 1 .708 0z"/>
                    </svg>
                    </div>
                    <div class="col-lg-3">
                        <a data-toggle="collapse" href="#nuesseDetails4">
                            <svg width="1.5em" height="1.5em" style="margin-top:5px;" viewBox="0 0 16 16" class="bi bi-info-circle-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412l-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM8 5.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
                              </svg>
                        </a>   
                    </div>
                </div>
                      
                <div class="collapse" id="nuesseDetails4">
                    <div class="row">
                        <div class="col-lg-12">
                            <i>Durchschnittlicher Nährwert je 100g</i>
                        </div>
                        <div class="col-lg-10">
                            Energie
                        </div>
                        <div class="col-lg-2">
                            2470kJ/596Kcal
                        </div>
                        <div class="col-lg-10">
                            Fett
                        </div>
                        <div class="col-lg-2">
                            51,00g
                        </div>
                        <div class="col-lg-10">
                            davon gesättigte Fettsäuren
                        </div>
                        <div class="col-lg-2">
                            4,6g
                        </div>
                        <div class="col-lg-10">
                            einfach ungesättigte Fettsäuren
                        </div>
                        <div class="col-lg-2">
                            6,5g
                        </div>
                        <div class="col-lg-10">
                            mehrfach ungesättigte Fettsäuren
                        </div>
                        <div class="col-lg-2">
                            39,9g	
                        </div>
                        <div class="col-lg-10">
                            Kohlenhydrate
                        </div>
                        <div class="col-lg-2">
                           3,3g
                        </div>
                        <div class="col-lg-10">
                            davon Zucker
                        </div>
                        <div class="col-lg-2">
                            2,9g
                        </div>
                        <div class="col-lg-10">
                            Eiweiß
                        </div>
                        <div class="col-lg-2">
                            31,0g
                        </div>
                        <div class="col-lg-10">
                            Salz
                        </div>
                        <div class="col-lg-2">
                            < 0,13g
                        </div>
                        <div class="col-lg-10">
                            Magnesium
                        </div>
                        <div class="col-lg-2">
                            684,5mg
                        </div>
                        <div class="col-lg-12 ">
                            <p style="margin-top:20px;">
                            Alle Angaben unterliegen den üblichen Schwankungen von Naturprodukten.
                            </p>
                        </div>
                    </div>		
                </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 mb-4">
                <div class="card h-100">
                <a href="#"><img class="card-img-top  img-responsive" src="images/produkte/lebkuchen_2.jpg" alt=""></a>
                <div class="card-body">
                    <h4 class="card-title">
                    <a href="#">Edle Hanfschokolade</a>
                    </h4>
                    <h5>auf Anfrage</h5>
                    <p class="card-text">Köstlich veredelte Hanfschokolade, hergestellt in Bayern.
                    </p>
                </div>
                <div class="card-footer" style="display:none;">
                    <small class="text-muted">&#9733; &#9733; &#9733; &#9733; &#9734;</small>
                </div>
                </div>
            </div>
        </div>
        <div class="row" style="margin-top:50px">
            <div class="col-lg-4 col-md-6 mb-4">
                <div class="card h-100">
                <a href="#"><img class="card-img-top  img-responsive" src="images/produkte/lebkuchen.jpg" alt=""></a>
                <div class="card-body">
                    <h4 class="card-title">
                    <a href="#">Hanflebkuchen </a>
                    </h4>
                    <h5>3Stk/Pck 5,00€</h5>
                    <p class="card-text">Hanflebkuchen im 3er Pack! Für die stille süße Zeit, zum Naschen oder Verschenken!</p>
                </div>
                    <div class="card-footer" style="display:none;">
                        Derzeit nicht lieferbar
                    </div>
                </div>
             </div>

             

             
    </div>    
    </div>    
    <div class="col-lg-3">
        
        <p   *ngIf=" getTotal()>0" class="text-center"><b>Übersicht</b></p>
                <div *ngFor="let produkt of produktliste;">                
                <div *ngIf="produkt.anzahl >0" class="row">
                        <div class="col-lg-1">
                            {{produkt.anzahl}}
                        </div>
                        <div class="col-lg-7">
                            {{produkt.titel}}
                        </div>
                        <div class="col-lg-1">
                            {{produkt.anzahl * produkt.price}}€
                        </div>
                        <div class="col-lg-1">
                            <p (click)=deleteProdukt(produkt)>
                                <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-trash" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                                    <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                                  </svg>
                            </p>
                        </div>
                    </div>  
               <br>
            </div>
            
            <div *ngIf=" getTotal()>0">
                Gesamtkosten: {{ getTotal() }}€    
            </div>    
            <div class="col-lg-12" style="margin-top:10px;" *ngIf=" getTotal()>0"> 
                <button  data-toggle="modal" data-target="#exampleModal2" class="btn btn-primary">Jetzt Bestellung anfragen</button>
            </div>            
            <div class="col-lg-12" *ngIf="success" style="color:green;font-size:18px;">
                {{success}}
            </div>  
    </div>

</div>
</div>
</section>
<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Produkt zu Ihrer Anfrage hinzufügen</h5>
        </div>
        <div class="modal-body">
          Bitte wählen Sie die Anzahl aus:<br>
          <select class="form-control" [(ngModel)]="anzahl1" id="anzahl1">
            <option>1</option>
            <option>2</option>
            <option>3</option>
            <option>4</option>
            <option>5</option>            
            <option>5</option>
            <option>6</option>
            <option>7</option>            
            <option>8</option>
            <option>9</option>            
            <option>10</option>
          </select>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Abbrechen</button>
          <button type="button"   class="close" data-dismiss="modal" id="button_1" (click)="addProdukt()"  class="btn btn-primary">Hinzufügen</button>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" id="exampleModal2" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Anfrage abschicken</h5>
        </div>
        <div class="modal-body">
            Wir bearbeiten jede Anfrage persönlich und schnellst möglich. 
            Wir freuen uns darauf deine Bestellanfrage zu prüfen und dir deine gesunden Produkte auf bestem Weg zu liefern.<br>
            <form id="contact-form" name="contact-form" >
                
              <div  class="col-lg-12" style="color:red;font-size:18px;" *ngIf="error">
                {{error}}
                </div>  

                <!--Grid row-->
                <div class="row">
      
                  <!--Grid column-->
                  <div class="col-md-6">
                    <div class="md-form mb-0">
                      <input type="text" [(ngModel)]="name" id="name" name="name" class="form-control">
                      <label for="name" class="">Name</label>
                    </div>
                  </div>
                  <!--Grid column-->
      
                  <!--Grid column-->
                  <div class="col-md-6">
                    <div class="md-form mb-0">
                      <input type="text" [(ngModel)]="email" id="email" name="email" class="form-control">
                      <label for="email" class="">E-Mail</label>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="md-form mb-0">
                      <input type="text" [(ngModel)]="telefon" id="telefon" name="telefon" class="form-control">
                      <label for="email" class="">Telefon</label>
                    </div>
                  </div>
                  <!--Grid column-->
      
                </div>
                <!--Grid row-->
      
      
                <!--Grid row-->
                <div class="row">
      
                  <!--Grid column-->
                  <div class="col-md-12">
      
                    <div class="md-form">
                      <textarea type="text" [(ngModel)]="text" id="message" name="message" rows="2" class="form-control md-textarea"></textarea>
                      <label for="message">Deine Adresse</label>
                    </div>
      
                  </div>
                </div>
                <div class="row">
      
                    <!--Grid column-->
                    <div class="col-md-12">        
                      <div class="md-form">                        
                            <input type="checkbox"  [(ngModel)]="dsgvo" name="dsgvo"  class="form-check-input" id="exampleCheck1">
                             <label class="form-check-label" for="exampleCheck1" style="margin-left:5px;"> Ich akzeptiere die <a href='/dsgvo' target='_blank'>Datenschutzhinweise</a> </label>
                      </div>        
                    </div>
                </div>
                <p   *ngIf=" getTotal()>0" class="text-center"><b>Übersicht</b></p>
                <div *ngFor="let produkt of produktliste;">                
                <div *ngIf="produkt.anzahl >0" class="row">
                        <div class="col-lg-1">
                            {{produkt.anzahl}}
                        </div>
                        <div class="col-lg-7">
                            {{produkt.titel}}
                        </div>
                        <div class="col-lg-1">
                            {{produkt.anzahl * produkt.price}}€
                        </div>
                    </div>  
               <br>
            </div>
            
            <div *ngIf=" getTotal()>0">
                Gesamtkosten: {{ getTotal() }}€    
            </div>    
                <!--Grid row-->
      
              </form>
      
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Abbrechen</button>
          <button type="button"   class="close" id="button_1"  data-dismiss="modal" (click)="checkMail()"  class="btn btn-primary">Anfrage senden</button>
        </div>
      </div>
    </div>
  </div>