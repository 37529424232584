import { Component } from '@angular/core';
import { NewsService } from './news.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'hanf';
  year = new Date();
  FullYear = this.year.getFullYear();
  newsTitle:string = "";

  constructor(private news:NewsService) { 
    let val = news.getNews().subscribe(val=>{
          this.newsTitle = val.text;

    console.log(val);
  });
 }

}
