import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-standrote',
  templateUrl: './standrote.component.html',
  styleUrls: ['./standrote.component.css']
})
export class StandroteComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
