
  <header role="banner" id="fh5co-header" style="background-color: white;">
    <nav class="navbar navbar-default" style="color:black;">
      <!-- Mobile Toggle Menu Button -->
<div>
<span class="navbar-brand" style="padding:20px;">
<img class="img-fluid"  style="width:150px;" src="images/logo_trans.png" />
</span>  
</div>
<a href="#" class="js-fh5co-nav-toggle fh5co-nav-toggle" data-toggle="collapse"
data-target="#navbar" aria-expanded="false" aria-controls="navbar"><i></i>
</a>     
<div id="navbar" class="navbar-collapse collapse">         
<ul class="nav navbar-nav ml-auto " style="float:left;margin-left:10%;font-size:20px;">
<li class="active"><a href="#" data-nav-section="home"><span>Home</span></a></li>          
<li><a href="https://rehmhanf.company.site/" ><span>Shop</span></a></li>            


<li><a href="#" data-nav-section="aboutUs"><span>Über uns</span></a></li>
<li><a href="#" data-nav-section="standorte"><span>Standorte</span></a></li>

<li><a href="#" data-nav-section="allergiker"><span>Für Allergiker</span></a></li>

<li><a href="#" data-nav-section="regionalitaet"><span>Regionalität</span></a></li>

<li><a href="#" data-nav-section="verpackung"><span>Verpackung</span></a></li>
<!--<li><a href="produkte" data-nav-section="Shop"><span>Shop</span></a></li>!-->
<li><a href="#" data-nav-section="contact"><span>Kontakt</span></a></li>          
</ul>  
<ul  class="nav navbar-nav ml-auto " style="float:right;">
<li> <img class="img-fluid"  style="width:150px;padding:5px;" src="images/naturpark_trans.png" /></li>
</ul>
</div>
<div *ngIf="newsTitle" style="width:100%;height:50px;background-color: #2b542c;text-align:center;color:white;text-decoration: bold;font-size:20px;vertical-align: middle;padding-top:10px;">
  {{newsTitle}}
</div>
</nav>

</header>

<router-outlet></router-outlet>

<footer id="footer" role="contentinfo">
  <div class="container">
    <div class="row row-bottom-padded-sm">
      <div class="col-md-12">
        <p class="copyright text-center">&copy; {{FullYear}} <a href="index.html"> Christian Rehm </a>
        <a style="margin-left:20px;" href="impressum">Impressum</a></p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 text-center">
        <ul class="social social-circle">
          <li><a target="_blank" href="https://www.facebook.com/rehmHanf"><i class="icon-facebook"></i></a></li>
          <li><a target="_blank" href="https://www.instagram.com/rehmshanfprodukte/"><i class="icon-instagram"></i></a></li>
        </ul>
      </div>
    </div>
  </div>
</footer>