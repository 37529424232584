import { Component, OnInit } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

const httpOptions = {
  headers: new HttpHeaders({ 
    'Access-Control-Allow-Origin':'*',
  })
};

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})

export class HomeComponent implements OnInit {

  name:string = "";
  email:string ="";
  telefon:string ="";
  betreff:string="";
  text:string="";
  success:string = "";
  error:string = "";
  apiUrl: string = "https://www.winkler-it.com/hanf/sendMail.php";
  auth: any = "Hanf2020!";
  dsgvo: boolean = false;

  
  sendForm(){
    if(this.name =="" || this.email =="" ||this.telefon ==""|| this.betreff =="" || this.text ==""){
      alert("Bitte alle Felder ausfüllen");
    }
  }

  constructor(private httpClient: HttpClient) { }

  sendPostRequest(data: Object): Observable<Object> {
    return this.httpClient.post(this.apiUrl, data);
  }

  checkMail(){
    
    console.log(this.name);
    console.log(this.email);
    console.log(this.telefon);
    console.log(this.betreff);    
    console.log(this.dsgvo);
    
    let data = {
      "name": this.name,
      "subject": this.betreff,
      "phone": this.telefon,
      "text": this.text,
      "email": this.email,
      "auth": this.auth
     }

    if(this.name  && this.email && this.telefon && this.betreff && this.text && this.dsgvo){
      this.error = "";
        console.log(data);
        this.sendPostRequest(data).subscribe(val=>{
          if(val==1){
            this.success = "Email erfoglreich verschickt!";
          }else{
            this.error = `Fehler aufgetreten +${val}`;
          }
        })
    }else{
      this.error = "Bitte alle Felder ausfüllen und die Datenschutzerklärung akzeptieren!";
    }

   
  }

  getInstagramContent(){


  var url= "https://graph.facebook.com/v9.0/instagram_oembed?url=https://www.instagram.com/p/CHkBpdQh0iV/&access_token=366858744624662|2EIX0rQsw19kYK7yTXx06-ftjqc"
   //var url= "https://graph.facebook.com/ig_hashtag_search?user_id=43656135884&q=rehmshanf";
   

    this.httpClient.get(url).subscribe(val=>{
      console.log(val);
    });


    //console.log().
  //  {"access_token":"366858744624662|2EIX0rQsw19kYK7yTXx06-ftjqc","token_type":"bearer"}
  }



  ngOnInit(): void {
  }

}
