import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ImpressumComponent } from './impressum/impressum.component';
import { HomeComponent } from './home/home.component';
import { StandroteComponent } from './standrote/standrote.component';
import { StandorteComponent } from './standorte/standorte.component';
import { RezepteComponent } from './rezepte/rezepte.component';
import { ProdukteComponent } from './produkte/produkte.component';

import { HttpClientModule } from '@angular/common/http';
import { DsgvoComponent } from './dsgvo/dsgvo.component';
import { ShopComponent } from './shop/shop.component';
import { NewsComponent } from './news/news.component';
import { AllergikerComponent } from './allergiker/allergiker.component';
@NgModule({
  declarations: [
    AppComponent,
    ImpressumComponent,
    HomeComponent,
    StandroteComponent,
    StandorteComponent,
    RezepteComponent,
    ProdukteComponent,
    DsgvoComponent,
    ShopComponent,
    NewsComponent,
    AllergikerComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule
  ],
  providers: [HttpClientModule],
  bootstrap: [AppComponent]
})
export class AppModule { }
