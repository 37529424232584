import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-standorte',
  templateUrl: './standorte.component.html',
  styleUrls: ['./standorte.component.css']
})
export class StandorteComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
