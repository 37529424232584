
<div id="slider" data-section="home" style="height: 100%;">
  <div class="owl-carousel owl-carousel-fullwidth" style="height: 100%;">
    <div class="item" style="background-image:url(images/img_1.jpg)">
      <div class="overlay"></div>
      <div class="container" style="position: relative;">
        <div class="row">
          <div class="col-md-12 text-left">
            <div class="fh5co-owl-text-wrap">
              <div class="fh5co-owl-text">
                <h1 class="fh5co-lead to-animate">Beste Hanfprodukte aus eigener Produktion</h1>
                <h2 class="fh5co-sub-lead to-animate">100% Qualität. 100% Region. 100% Nachhaltigkeit.</h2>
                  <p class="to-animate-2"><a href="https://rehmhanf.company.site/"  class="btn btn-primary btn-lg">Alles über unsere Produkte</a></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


   <div class="item" style="background-image:url(images/img_2.jpg)">
     <div class="container" style="position: relative;">
       <div class="row">
        <div class="col-md-12 text-left">
          <div class="fh5co-owl-text-wrap">
            <div class="fh5co-owl-text" style="background:rgba(255,255,255,0.7);padding:15px;">
              <h1 class="fh5co-lead to-animate" style="font-weight: 700;
               margin-bottom: 20px;
               margin-top: 100px;
               text-transform: uppercase;
               letter-spacing: 1px;
               color: #2b542c;
               font-size: 50px;
               line-height: 50px;
               ">Hanf Ge-Nüsschen</h1>
               <h2 style="font-size: 30px;
               line-height: 30px;
               line-height: 42px;
               font-weight: 100;
               margin-bottom: 30px;color: #2b542c;
               ">Jetzt neu und gesund! Hanfnüsse ungeschält und aus regionalem Anbau.<br>Perfekt für den Start in den Tag.</h2>
                 <p class="to-animate-2"><a href="https://rehmhanf.company.site/" class="btn btn-primary btn-lg">Gleich bestellen</a></p>
            
         </div>
        </div>
      </div>
         <div class="col-md-4 col-md-push-1 col-sm-4 col-sm-push-1">
           <div ></div>
         </div>

       </div>
     </div>
   </div>

  </div>
</div>
<div id="fh5co-produkte" data-section="aboutUs" >

<div class="container">
  <div class="row row-bottom-padded-lg" id="produkte" >
      <h2 class="h1-responsive font-weight-bold text-center  to-animate to-animate" style="margin-top:100px;"  >Über uns </h2>
      <div class="row" style="margin-bottom:40px;" >
  
      </div>
      
    <div class="col-md-4 to-animate">
        <img class="img-fluid" style="max-width:100%;" src="images/family.jpeg" >
    </div>

      
    <div class="col-md-8 to-animate">
      <h2 class="to-animate">Wie alles begann... </h2>
      Der landwirtschaftliche Familienbetrieb besteht bereits <b>seit vielen Generationen.</b> <br><br>

      Wir haben immer wieder was neues versucht, so experentieren wir mittlerweile in 3ter Generation (seit 1969) mit umweltschonender Bodenbearbeitung, 
      und haben den Betrieb seit 1994 komplett darauf umgestellt.<br><br> 
      
      2013 wurde die Trocknungsanlage auf Biogene Brennstoffe (Hackschnitzel) umgerüstet und der 
      Betrieb ist seither komplett ohne Heizöl. Dies war auch der Zeitpunkt an dem die GmbH gegründet wurde, 
      diese ist ein Ableger der Landwirtschaft, und auf das Trocknen von landwirtschaftlichen Produkten
       spezialisiert und voll in den Betrieb eingegliedert. <br><rb></rb>
      
      2019 begannen wir mit dem Anbau von Hanf. Genau genommen Nutzhanf (Cannabis Sativa). 
      Die ersten versuche mit Hanftee waren sehr vielversprechend. Bei den Hanfnüsschen mussten wir lange probieren, 
      bis wir die Reinigungstechnik so weit im Griff hatten und eine zufriedenstellende Qualität anbieten konnten.
       In Zusammenarbeit mit anderen Kollegen, die auch Hanfprodukte herstellen, 
       tauschen wir nicht nur unser Wissen, sondern auch Produkte aus, um das Angebot zu verbreitern.
      Ebenso mit handwerklichen Betrieben die für uns Spezialitäten aus unserem Hanf herstellen, 
      wie z.B. die Hanfschokolade oder unsere Lebkuchen.<br><br>
      <p class="text-center"><a href="https://rehmhanf.company.site/" class="btn btn-primary btn-lg text-center btn-block">Zum Shop</a></p>
    </div>
    
    
    <div class="col-md-4 to-animate">
      <img class="img-fluid text-center" style="width:250px;" src="images/naturpark_trans.png">           
</div>
      <div class="col-md-8 ">
        <h3 style="margin-left:20px;">Alles regional. Alles 100% hergestellt im eigenen Betrieb.</h3>
      </div>
  </div>
</div>
</div>

<div id="fh5co-produkte" data-section="allergiker"id="allergiker" >

  <div class="container">
    <div class="row row-bottom-padded-lg"  >
        <h2 class="h1-responsive font-weight-bold text-center  to-animate to-animate" style="margin-top:100px;"  >Thema Allergie, Nussallergie </h2>
        <div class="row" style="margin-bottom:40px;" >
    
        </div>
        
      <div class="col-md-4 to-animate">
          <img class="img-fluid" style="max-width:100%;" src="images/allergie.jpg" >
      </div>
  
        
      <div class="col-md-8 to-animate">
        <h2 class="to-animate">Bisher ist keine Allergie gegen Hanf bekannt. </h2>
        Hanf lässt sich grundsätzlich als Ersatz für Nüsse verwenden, auch wenn die Frucht als Nuss oder Nüsschen bezeichnet wird,
        gibt es keine Probleme bei Nussallergie.<br><br>
        <b>Geriebene Nüsse</b>:<br>
        Hanfsamen mit Schale in einem Mixer zerkleinern, dann kann man 1 zu 1 geriebene Nüsse ersetzen.
        (z.B.: Thermomix: max. 300g, 6-7Sekunden auf Stufe 10)
        Dieses Hanfschrot hat etwa 30% weniger Kalorien als geriebene Haselnüsse.
        <br>
<b>Gehackte Nüsse:</b><br>
Diese kann man einfach mit geschälten Hanfsamen ersetzen.<br>Die Vorteile gegenüber den Ersatz durch Mandeln hat man vor allem dadurch,
 das Hanf keine Blausäure enthält.<br><br>
Bei uns werden die Hanfsamen durch eine spezielle Maschine nach gereinigt, welche die Harzschicht auf den Samen entfernt. Diese Schicht ist etwas klebrig, enthält THC und sammelt in der Natur fremde Pollen und Pilzsporen. Durch das entfernen dieser Schicht entfernen wir auch die unerwünschten Stoffe auf den Samen und verbessern zugleich noch den Geschmack.
Diesen Arbeitsschritt führen wir bei allen Hanfsamen durch, bevor wir sie Verarbeiten, egal ob zu Öl oder anderen Produkten, dadurch können wir geringe THC-Werte garantieren und zugleich sicherstellen dass keine allergenen Pollen in den Produkten enthalten sind.

 </div>
      
     
  </div>
  </div>
</div>

<div id="fh5co-produkte" data-section="regionalitaet"id="regionalitaet" >

  <div class="container">
    <div class="row row-bottom-padded-lg"  >
        <h2 class="h1-responsive font-weight-bold text-center  to-animate to-animate" style="margin-top:100px;"  >Regionalität </h2>
        <div class="row" style="margin-bottom:40px;" >
    
        </div>
        
      <div class="col-md-4 to-animate">
          <img class="img-fluid" style="max-width:100%;" src="images/regionalitaet.jpg" >
      </div>
  
        
      <div class="col-md-8 to-animate">

        Unsere Hanfprodukte kommen weitestgehend von unseren Feldern und eigenem Anbau.
Ausnahme ist das CBD-Öl, da wir eine gegenseitige Befruchtung der Sorten verhindern wollen, um die Qualität des Tee's nicht zu gefährden.<br><br>

Auch die Sonnenblumen stammen aus eigenem Anbau.<br><br>

Die Ernte, das Trocknen und die Reinigung erfolgt bei uns auf dem Hof.
Natürlich liegt auch das Ölpressen, Filtern und Abfüllen in unseren Händen.
Einige Produkte werden von regionalen Betrieben hergestellt, die wir mit unserem Hanf beliefern.  <br><br>

Wir achten auf kurze Wege, viele Produkte verlassen unseren Hof erst, auf dem Weg zum Kunden.
Manchmal hat der Tee keine 500m vom Feld zum Laden, manchmal sind es auch ein paar km, je nachdem wie weit das Feld gerade weg ist, aber die komplette Verarbeitung zum fertigen Tee passiert bei uns.

</div>

 </div>
      
     
  </div>
</div>

<div id="fh5co-produkte" data-section="verpackung"id="verpackung" >

  <div class="container">
    <div class="row row-bottom-padded-lg"  >
        <h2 class="h1-responsive font-weight-bold text-center  to-animate to-animate" style="margin-top:100px;"  >Verpackung </h2>
        <div class="row" style="margin-bottom:40px;" >
    
        </div>
        
      <div class="col-md-4 to-animate">
          <img class="img-fluid" style="max-width:100%;" src="images/portfolio.jpg" >
      </div>
  
        
      <div class="col-md-8 to-animate">
        Warum wir so verpacken, wie wir verpacken.<br>
        
        Wir setzen wo immer es geht auf Papier, wollen aber auf keinen Fall die Qualität der Produkte gefährden und natürlich muss auch das Produkt sicher beim Kunden ankommen.
        Manche Produkte sollen so dicht wie möglich verpackt werden. Dazu haben wir beim Tee einen versiegelten extrem dünnen Innenbeutel aus Polypropylen im Papierbeutel. Das spart 70% Kunststoff gegenüber einem Plastikbeutel. Mit reinen Papierbeuteln hatten wir leider keine guten Erfahrungen gemacht.
        <br><br>
        Die Nüsschen verpacken wir in Papiertüten mit kleinem Fenster.<br>        
        Die Nudeln sind wie der Tee in versiegelten Papiertüten mit Innenfolie.<br>
        
        Die geschälten Hanfsamen, das Hanfmehl und die Leckerlis haben wir in sog. Verbundverpackungen, welche luftdicht sind, auch mit Papier verstärkt und wiederverschließbar.
        <br><br>
        Die Öle und Sirup haben wir in Glasflaschen. Da haben wir robuste Flaschen mit dunkler Farbe gewählt, um einen Bruch beim Transport und eine Beeinträchtigung der Qualität durch Sonnenlicht zu verhindern.
        Bei den Flaschen verwenden wir Luftpolsterfolie zum Schutz im Paket.
        
        
        
        
        
</div>

 </div>
      
     
  </div>
</div>
  <!--
  
  <div class="container">x^    <div class="row" id="team" data-section="services">
      <div class="col-md-12 section-heading text-center to-animate">
        <h2>Über uns </h2>
      </div>
      <div class="col-md-12">
        <div class="row row-bottom-padded-lg">
          <div class="col-md-4 text-center to-animate">
            <div class="person">
              <img src="images/person2.jpg" class="img-responsive img-rounded" alt="Person">
              <h3 class="name">Christian Rehm</h3>
              <div class="position">Geschäftsführer</div>
              <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor .</p>
              <ul class="social social-circle">
                <li><a href="#"><i class="icon-twitter"></i></a></li>
                <li><a href="#"><i class="icon-linkedin"></i></a></li>
                <li><a href="#"><i class="icon-instagram"></i></a></li>
                <li><a href="#"><i class="icon-github"></i></a></li>
              </ul>
            </div>
          </div>
          <div class="col-md-4 text-center to-animate">
            <div class="person">
              <img src="images/person3.jpg" class="img-responsive img-rounded" alt="Person">
              <h3 class="name">Max Mustermann</h3>
              <div class="position">Produktion</div>
              <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea t</p>
              <ul class="social social-circle">
                <li><a href="#"><i class="icon-twitter"></i></a></li>
                <li><a href="#"><i class="icon-linkedin"></i></a></li>
                <li><a href="#"><i class="icon-instagram"></i></a></li>
                <li><a href="#"><i class="icon-dribbble"></i></a></li>
              </ul>
            </div>
          </div>
          <div class="col-md-4 text-center to-animate">
            <div class="person">
              <img src="images/person4.jpg" class="img-responsive img-rounded" alt="Person">
              <h3 class="name">Musterfrau Martina</h3>
              <div class="position">Vertrieb</div>
              <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dol</p>
              <ul class="social social-circle">
                <li><a href="#"><i class="icon-twitter"></i></a></li>
                <li><a href="#"><i class="icon-linkedin"></i></a></li>
                <li><a href="#"><i class="icon-instagram"></i></a></li>
                <li><a href="#"><i class="icon-github"></i></a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<div id="fh5co-testimonials" data-section="testimonials">
  <div class="container">
    <div class="row">
      <div class="col-md-12 section-heading text-center">
        <h2 class="to-animate">Kunden sind begeistert...</h2>
        <div class="row">
          <div class="col-md-8 col-md-offset-2 subtext to-animate">
            <h3>Qualität und beste regionale Produkte, machen sich bezahlt. Unsere Kunden sind begeistert.</h3>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4">
        <div class="box-testimony to-animate">
          <blockquote>
            <span class="quote"><span><i class="icon-quote-left"></i></span></span>
            <p>&ldquo; Kundenbericht 1 über bsp. Hanftee .&rdquo;</p>
          </blockquote>
          <p class="author">John Doe, CEO<span class="subtext">Creative Director</span></p>
        </div>

      </div>
      <div class="col-md-4">
        <div class="box-testimony to-animate">
          <blockquote>
            <span class="quote"><span><i class="icon-quote-left"></i></span></span>
            <p>&ldquo; Kundenbericht 1 über bsp. Hanftee .&rdquo;</p>
          </blockquote>
          <p class="author">John Doe, CEO<span class="subtext">Creative Director</span></p>
        </div>


      </div>
      <div class="col-md-4">
        <div class="box-testimony to-animate">
          <blockquote>
            <span class="quote"><span><i class="icon-quote-left"></i></span></span>
            <p>&ldquo; Kundenbericht 1 über bsp. Hanftee .&rdquo;</p>
          </blockquote>
          <p class="author">John Doe, CEO<span class="subtext">Creative Director</span></p>
        </div>

      </div>
    </div>
  </div>
</div>

<div id="fh5co-pricing" data-section="pricing">
  <div class="container">
    <div class="row">
      <div class="col-md-12 section-heading text-center">
        <h2 class="single-animate animate-pricing-1">Preise</h2>
        <div class="row">
          <div class="col-md-8 col-md-offset-2 subtext single-animate animate-pricing-2">
            <h3>Jetzt unsere Produkte schnell und einfach online bestellen </h3>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-3 col-sm-6">
        <div class="price-box to-animate">
          <h2 class="pricing-plan">Starter Paket</h2>
          <div class="price"><sup class="currency">€</sup>16</div>
          <p>2x Tee u. 1x Nüsschen</p>
          <hr>
          <ul class="pricing-info">
            <li>2x Hanftee</li>
            <li>1x Hanf Nüsschen</li>
          </ul>
          <a href="#" class="btn btn-default btn-sm">Jetzt bestellen</a>
        </div>
      </div>
      <div class="clearfix visible-sm-block"></div>
      <div class="col-md-3 col-sm-6 to-animate">
        <div class="price-box popular">
          <div class="popular-text">Empfehlung</div>
          <h2 class="pricing-plan">Plus</h2>
          <div class="price"><sup class="currency">€</sup>79</div>
          <p>Alle Produkte für circa zwei Monate</p>
          <hr>
          <ul class="pricing-info">
            <li>2x Hanftee</li>
            <li>5x Hanf Genüsschen</li>
            <li>5x Hanf Genüsschen</li>


          </ul>
          <a href="#" class="btn btn-primary btn-sm">Jetzt bestellen</a>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3 col-sm-6">
          <div class="price-box to-animate">
            <h2 class="pricing-plan">Premium Paket</h2>
            <div class="price"><sup class="currency">€</sup>120</div>
            <p>2x Tee u. 1x Nüsschen</p>
            <hr>
            <ul class="pricing-info">
              <li>2x Hanftee</li>
              <li>1x Hanf Nüsschen</li>
            </ul>
            <a href="#" class="btn btn-default btn-sm">Jetzt bestellen</a>
          </div>
        </div>

    </div>
  </div>
</div>

-->


<!--
<div id="fh5co-bilder" data-section="bilder">
  
  <div class="container">
    <div class="row">
      
      <div class="col-md-12">        
          <div class="elfsight-app-00e106ee-b8da-4f4e-a38d-06365ccda69f"></div>
    </div>
  </div>
     </div>
</div>
-->

<div id="fh5co-press" data-section="standorte">
  <!--Section: Contact v.2-->
  <div class="container">
    <div class="row">
      
    <h2 style="margin-top:0px;margin-bottom:80px;" class="font-weight-bold text-center my-4 to-animate">Hier bekommt ihr unsere Produkte</h2>
    
    
    <div class="col-md-12" >
      <iframe style="width:100%;height:500px;" src="https://www.google.com/maps/d/embed?mid=1bHQn39-d_RehiVwbAN9NqDL1BMMO0JEf"></iframe>

    </div>
      <div class="col-md-12">
        <div class="col-md-3">
          <h3>Hofverkauf </h3>
            <p >
              Mo.-Sa.nach Vereinbarung
            </p>
        </div>
        <div class="col-md-3">
          <h3>REWE Riedenburg</h3>
        <p>Am Gewerbepark 6<br>
          93339 Riedenburg</p>
        </div>
        <div class="col-md-3">  <h3>Edeka Kubitzky  </h3>     <p>
          Galgenbergring 19<br>
          93336 Altmannstein<br></p>
        </div>
        <div class="col-md-3">  <h3>Klosterstadl  </h3>     <p>
          Klosterstraße 5<br>
          93188 Pielenhofen<br></p>        
        </div>
        <div class="col-md-3">  <h3>Hofladen Limmer  </h3>     <p>
          Hausmühler Straße 5<br>
          93101 Pfakofen<br></p>        
        </div>
        <div class="col-md-3">  <h3> Käse & Feines Keil </h3>     <p>
          Johannesgasse 7<br>
          93339 Riedenburg<br></p>        
        </div>
          
        <div class="col-md-3">
          <h3>Blumen Riedl</h3>
          <p>Riedenburger Str. 11<br>93336 Altmannstein<br><a href="http://www.blumenriedel.com/" targe="_blank">blumenriedel.com</a>
          </p>
        </div>      
      
        <div class="col-md-3">
          <h3>Dollinger Dorfladen</h3>
        <p>          Am Bahnhof 1<br>85129 Oberdolling<br><a href="https://www.facebook.com/Dollinger-Dorfladen-1289659774515344/" targe="_blank">Dollinger Dorfladen</a></p>
        </div>
        <div class="col-md-12">
        Bringt gerne eure Behälter mit, um für eine nachhaltigere Zukunft zusätzlichen Verpackunsgmüll zu vermeiden.
        <br>
        Wir suchen ständig nach neuen Orten, um unsere Produkte in der Region platzieren zu können.
        Solltet Ihr Interesse haben, selbst als Anbieter aufzutreten, schreibt uns gerne.
        </div>
    </div>
  </div>
     </div>
</div>

<div id="fh5co-contact" data-section="contact">
  <!--Section: Contact v.2-->
  <div class="container">
  <div class="row">

    <!--Section heading-->
    <h2 class="h1-responsive font-weight-bold text-center my-4 to-animate">Fragen zu unseren Produkten? Schreib uns!</h2>
    <!--Section description-->
    <p class="text-center w-responsive mx-auto mb-5">
      Du hast Fragen zu unseren Produkten, Feedback oder möchtest mehr Informationen über unseren Betrieb?
      Dann schreib uns gerne!
    </p>

    <div class="row">

      <!--Grid column-->
      <div *ngIf="error" class="col-md-9 mb-md-0 mb-5 text-center" style="color:red;">
        {{error}}
      </div>
      <div *ngIf="success" class="col-md-9 mb-md-0 mb-5 text-center" style="color:green;">
        {{success}}
      </div>
      <div *ngIf="!success" class="col-md-9 mb-md-0 mb-5">
        <form id="contact-form" name="contact-form" >

          <!--Grid row-->
          <div class="row">

            <!--Grid column-->
            <div class="col-md-6">
              <div class="md-form mb-0">
                <input type="text" [(ngModel)]="name" id="name" name="name" class="form-control">
                <label for="name" class="">Name</label>
              </div>
            </div>
            <!--Grid column-->

            <!--Grid column-->
            <div class="col-md-6">
              <div class="md-form mb-0">
                <input type="text" [(ngModel)]="email" id="email" name="email" class="form-control">
                <label for="email" class="">E-Mail</label>
              </div>
            </div>
            <div class="col-md-6">
              <div class="md-form mb-0">
                <input type="text" [(ngModel)]="telefon" id="telefon" name="telefon" class="form-control">
                <label for="email" class="">Telefon</label>
              </div>
            </div>
            <!--Grid column-->

          </div>
          <!--Grid row-->

          <!--Grid row-->
          <div class="row">
            <div class="col-md-12">
              <div class="md-form mb-0">
                <input type="text" [(ngModel)]="betreff" id="betreff" name="subject" class="form-control">
                <label for="subject" class="">Betreff</label>
              </div>
            </div>
          </div>
          <!--Grid row-->

          <!--Grid row-->
          <div class="row">

            <!--Grid column-->
            <div class="col-md-12">

              <div class="md-form">
                <textarea type="text" [(ngModel)]="text" id="message" name="message" rows="2" class="form-control md-textarea"></textarea>
                <label for="message">Deine Nachricht an uns</label>
              </div>

            </div>
          </div>
          <!--Grid row-->
          <div class="row">
      
            <!--Grid column-->
            <div class="col-md-12">        
              <div class="md-form">                        
                    <input type="checkbox"  [(ngModel)]="dsgvo"  name="dsgvo" class="form-check-input" id="exampleCheck1">
                     <label class="form-check-label" for="exampleCheck1" style="margin-left:5px;"> Ich akzeptiere die <a href='/dsgvo' target='_blank'>Datenschutzhinweise</a> </label>
              </div>        
            </div>
        </div>

        </form>

        <div class="text-center text-md-left">
          <button class="btn btn-primary" (click)="checkMail()">Abschicken</button>
        </div>
        <div class="status"></div>
      </div>
      <!--Grid column-->

      <!--Grid column-->
      <div class="col-md-3 text-center">
        <ul class="list-unstyled mb-0">
          <li><i class="fas fa-map-marker-alt fa-2x"></i>
            <p>Rehm GmbH<br>
              Limesstraße 2 <br>93336 Laimerstadt
            </p>
          </li>

          <li><i class="fas fa-phone mt-4 fa-2x"></i>
            <p>+49 176 30670284</p>
          </li>

          <li><i class="fas fa-envelope mt-4 fa-2x"></i>
            <p>info@rehm-hanf.de</p>
          </li>
        </ul>
      </div>
      </div>
    </div>
  </div>
</div>