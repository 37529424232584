import { Component, OnInit } from '@angular/core';
import { Produkt } from '../produkt';
import * as $ from 'src/js/jquery.min.js';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-produkte',
  templateUrl: './produkte.component.html',
  styleUrls: ['./produkte.component.css']
})
export class ProdukteComponent implements OnInit {
  produkt:string = "";
  produktliste: Array <Produkt> = [];
  anzahl1:number;
  selectedProduct: Produkt;
  name:string = "";
  email:string ="";
  telefon:string ="";
  betreff:string="";
  text:string="";
  success:string = "";
  dsgvo:boolean = false;
  error:string = "";
  apiUrl: string = "https://www.winkler-it.com/hanf/sendMail2.php";
  auth: any = "Hanf2020!";

  hanfnuesse250: Produkt = {
                              titel: "Hanfnüsse 250g",
                              price : 4.00,
                              anzahl: 0,
                              id:1
                            
                            }
  hanfnuesse500: Produkt = {
                              titel: "Hanfnüsse 500g",
                              price : 7.00,
                              anzahl: 0,
                              id:2
                            
                            }
  hanfoel: Produkt = {
                              titel: "Hanföl 250ml",
                              price : 8.50,
                              anzahl: 0,
                              id:3
                            
                            }
  hanf_kandiert: Produkt = {
                              titel: "Hanfüsse kandiert 100g",
                              price : 4.90,
                              anzahl: 0,
                              id:4
                            
                            }  
  hanf_geschaelt: Produkt = {
                              titel: "Hanfüsse geschält 250g",
                              price : 7.80,
                              anzahl: 0,
                              id:0                   
                            }


  constructor(private httpClient:HttpClient) { 
    this.produktliste.push(this.hanfnuesse250);    
    this.produktliste.push(this.hanfnuesse500);    
    this.produktliste.push(this.hanfoel);    
    this.produktliste.push(this.hanf_kandiert);    
    this.produktliste.push(this.hanf_geschaelt);




    
  }
  

  ngOnInit(): void {
    $(document).ready(function(){

      $('#exampleModal').on('show.bs.modal', function (event) {
        alert("asdasd");
        var button = $(event.relatedTarget)
        let product = button.data('whatever');
        alert(product);
        $('#button_1').on("click",this.addProduct(product));
      });
    });
  }

  selectProduct(produkt: Produkt){
    this.selectedProduct = produkt;
  }

  sendAnfrage(){

  }

  sendPostRequest(data: Object): Observable<Object> {
    return this.httpClient.post(this.apiUrl, data);
  }

  checkMail(){
    


    let data = {
      "name": this.name,
      "phone": this.telefon,
      "text": this.text,
      "email": this.email,
      "auth": this.auth,
      "produktliste": this.produktliste
     }

    if(this.name  && this.email && this.telefon && this.text && this.dsgvo){
      this.error = "";
        console.log(data);
        this.sendPostRequest(data).subscribe(val=>{
          if(val==1){
            
            this.success = "Email erfoglreich verschickt!";
                        
            this.produktliste.forEach(val => {
                  val.anzahl = 0;        
            });
          }else{
            this.error = `Fehler aufgetreten +${val}`;
          }
        })
    }else{
      this.error = "Bitte alle Felder ausfüllen und Datenschutzerklärung akzeptieren!";
    }

   
  }

  getTotal(){
    let sum = 0;
    this.produktliste.forEach(produkt => {
      sum += produkt.anzahl * produkt.price;
    });
    return sum;
  }

   addProdukt(){
     
    this.produktliste.forEach(val => {
      if(val.id == this.selectedProduct.id){
           val.anzahl = this.anzahl1;
        
      }
    });
    this.selectedProduct = null;
    this.anzahl1 = null;
  }
  deleteProdukt(produkt: Produkt){
    this.produktliste.forEach(val => {
        if(val.id == produkt.id){
          val.anzahl = 0;
        }
      });
  }



}
