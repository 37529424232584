import { Injectable } from '@angular/core';
import { HttpClient } from  '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class NewsService {
  private url = 'https://lvps91-250-112-201.dedicated.hosteurope.de/api/hanf/hanfApi.php';

  constructor(private http: HttpClient) { }


  getNews (){
    var formData: FormData = new FormData();
    formData.append('method', 'GET');
    formData.append('auth', 'asdasd');
    formData.append('value', 'null');

    const headers = new Headers({
               'Accept': 'application/json',
               'enctype': 'multipart/form-data'
           });

    return this.http.post<any>(this.url,formData)
  }
  getPosts() {
    return this.http.get(this.url);
  }

  updateNews(text){
    var formData: FormData = new FormData();
    formData.append('method', 'PUT');    
    formData.append('auth', 'asdasd');
    formData.append('value', text);

    const headers = new Headers({
               'Accept': 'application/json',
               'enctype': 'multipart/form-data'
           });

    return this.http.post<any>(this.url,formData)

  }
}
