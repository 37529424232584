import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DsgvoComponent } from './dsgvo/dsgvo.component';
import { HomeComponent } from './home/home.component';
import { ImpressumComponent } from './impressum/impressum.component';
import { NewsComponent } from './news/news.component';
import { ProdukteComponent } from './produkte/produkte.component';
import { ShopComponent } from './shop/shop.component';


const routes: Routes = [
  
  { path: 'home', component: HomeComponent },
  { path: 'impressum', component: ImpressumComponent },  
  { path: 'produkte', component: ProdukteComponent },  
  { path: 'dsgvo', component: DsgvoComponent },  
  { path: 'shop', component: ShopComponent },
  
  { path: 'news', component: NewsComponent },
  { path: '', component: HomeComponent }
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule] 
})
export class AppRoutingModule { }
